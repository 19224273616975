export class Navigation {
  constructor() {
    this.editionActive = false;
    this.backRoute = "";
    this.dashboard = {
      option: "",
    };
    this.account = {
      option: "",
    };
    this.subscription = {
      option: "",
    };
    this.favorites = {
      option: "",
    };
  }
};
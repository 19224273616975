import React from 'react';
import { BodyCss } from '../../../functions';

const Releases = () => {
  BodyCss()
  return (
    <div>
    </div>
  )
}

export default Releases;
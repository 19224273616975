export class Content {
  constructor() {
    this.artist = "";
    this.title = "";
    this.info = "";
    this.genres = "";
    this.producers = "";
    this.categories = "";
    this.mediatypes = "";
    this.idLinkYT = "";
    this.idLinkSPOTY = "";
    this.idLinkDRIVE = "";
    this.urlLinkWEB = "";
    this.urlLinkDOWNLOAD = "";
    this.imageSlider = "";
    this.imageVisor = "";
  }
};